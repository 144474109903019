import React from 'react'
import { Text } from '@superlogica/design-ui'
import { useCountdown } from 'hooks'

import * as S from './Countdown.styled'

interface CountdownProps {
  date: string
  color?: Color
}

const Countdown = ({ color = 'babar0', date }: CountdownProps) => {
  const [days, hours, minutes, seconds] = useCountdown(date)

  if (days + hours + minutes + seconds <= 0) {
    return null
  }

  return (
    <S.Wrapper>
      <S.DateDisplay>
        <Text
          className="time"
          font="heading"
          size="display02"
          color={color}
          weight="bold"
        >
          {days}
        </Text>
        <Text
          size="label03"
          color={color}
          letterSpacing={0.5}
          weight="bold"
          uppercase
        >
          Dias
        </Text>
      </S.DateDisplay>
      <S.DateDisplay>
        <Text
          className="time"
          font="heading"
          size="display02"
          color={color}
          weight="bold"
        >
          {hours}
        </Text>
        <Text
          size="label03"
          color={color}
          letterSpacing={0.5}
          weight="bold"
          uppercase
        >
          Horas
        </Text>
      </S.DateDisplay>
      <S.DateDisplay>
        <Text
          className="time"
          font="heading"
          size="display02"
          color={color}
          weight="bold"
        >
          {minutes}
        </Text>
        <Text
          size="label03"
          color={color}
          letterSpacing={0.5}
          weight="bold"
          uppercase
        >
          Min
        </Text>
      </S.DateDisplay>
      <S.DateDisplay>
        <Text
          className="time"
          font="heading"
          size="display02"
          color={color}
          weight="bold"
        >
          {seconds}
        </Text>
        <Text
          size="label03"
          color={color}
          letterSpacing={0.5}
          weight="bold"
          uppercase
        >
          Seg
        </Text>
      </S.DateDisplay>
    </S.Wrapper>
  )
}

export default Countdown
