import styled, { css } from 'styled-components'
import { parseColor } from '@superlogica/design-ui'
import { Link } from 'components/helpers'
import { rem } from 'polished'

export const Hash = styled(Link)`
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  padding: 16px;
  border-bottom: 1px solid transparent;
  font-size: ${rem(14)};
  letter-spacing: 1px;
  white-space: nowrap;
  transition: all 300ms ease-in-out;

  ${({ theme: { colors } }) => css`
    color: ${parseColor(colors.babar400)};

    &.active {
      border-color: ${parseColor(colors.sonic500)};
    }

    &.active,
    &:hover {
      color: ${parseColor(colors.babar900)};
    }
    &:hover {
      color: ${parseColor(colors.babar900)};
      background-color: ${parseColor(colors.babar25)};
    }
  `}
`

export const Item = styled.li`
  flex-grow: 1;
  text-align: center;
`

export const Items = styled.ul`
  display: flex;
  justify-content: space-between;
`

export const Nav = styled.nav`
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: -1px;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Wrapper = styled.div`
  top: 100px;
  z-index: 20;

  ${({ theme: { colors } }) => css`
    border-bottom: 1px solid ${parseColor(colors.babar75)};
    background: ${parseColor(colors.babar0)};
  `}
`
