import styled, { css } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'

export const CardCustomer = styled.div`
  padding: 1.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  max-width: 430px;
  position: relative;
  overflow: hidden;

  .arrow-card-customer,
  .arrow-card-customer-2 {
    position: absolute;
  }

  .arrow-card-customer {
    left: -245px;
    top: -80px;
  }

  .arrow-card-customer-2 {
    right: -210px;
  }

  span {
    font-size: 1.25rem;
    font-weight: 700;
  }

  ${({ theme }) => css`
    background-color: ${parseColor(theme.colors.babar0)};
  `}

  ${media.lessThan('md')} {
    span {
      font-size: 1rem;
    }
  }

  ${media.lessThan('xs')} {
    margin-top: 1rem;

    .arrow-card-customer {
      left: -250px;
    }

    .arrow-card-customer-2 {
      right: -215px;
    }
  }
`

export const Tiles = styled.div`
  margin-top: 40px;

  ${media.lessThan('sm')} {
    margin-top: 1.5rem;
  }
`

export const Caption = styled.div`
  position: relative;
  z-index: 10;
  max-width: 650px;
  padding: 40px 0;

  ${media.lessThan('sm')} {
    max-width: 500px;
    padding: 120px 0 60px;
  }
`

export const NextArrowLeft = styled.div`
  position: absolute;
  bottom: -10%;
  left: -120px;
  width: 250px;
  height: 250px;

  .vector-animation {
    path {
      stroke-width: 18px;
    }
  }

  ${media.lessThan('sm')} {
    width: 120px;
    height: 120px;
    left: -60px;
  }
`

export const NextPhoto = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  .vector-animation {
    width: 550px;
    height: 750px;
    margin: auto;
    top: 100px;
    right: -200px;
    z-index: 1;
  }

  ${media.greaterThan('md')} {
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 200px;
      height: 200px;
      z-index: 2;
      transform: rotate(45deg);
    }

    &:before {
      top: -100px;
    }

    &:after {
      top: 0px;
      right: -100px;
    }
  }

  ${({ theme: { colors } }) => css`
    &:before {
      background-color: ${parseColor(colors.sonic300)};
    }

    &:after {
      background-color: ${parseColor(colors.sonic400)};
    }
  `}

  ${media.lessThan('md')} {
    width: 100%;
    opacity: 0.1;
  }
`

export const NextSquare = styled.div`
  position: absolute;
  top: 0;
  right: -420px;
  width: 900px;
  height: 900px;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 40px;
    left: -40px;
    mask-image: url('${`./media/mask-hero.svg`}');
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;

    ${({ theme: { colors } }) => css`
      background-color: ${parseColor(colors.warning)};
    `}
  }

  ${media.lessThan('md')} {
    width: 700px;
    height: 700px;
    right: -400px;
  }
`

export const NextSquareContent = styled.div`
  width: 100%;
  height: 100%;
  mask-image: url('${`./media/mask-hero.svg`}');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  min-height: 700px;
  overflow: hidden;

  ${media.lessThan('sm')} {
    height: auto;
  }
`
