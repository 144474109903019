import React, { useEffect, useRef } from 'react'
import { Block, useScrollSpy } from '@superlogica/design-ui'
import { useConfig } from 'hooks'

import SectionHero from './Section.hero'
import SectionAbout from './Section.about'
import SectionPrice from './Section.price'
import SectionSponsorCTA from './Section.sponsorcta'
import SectionSponsors from './Section.sponsors'
import SectionForm from './Section.form'
import SectionMadeBy from './Section.madeby'

import * as S from './styled'

const View = () => {
  const { setConfig } = useConfig()

  const sectionRef = useRef<HTMLDivElement>(null)
  const active = useScrollSpy(sectionRef, {
    initial: 'inicio',
    offset: -100
  })

  useEffect(() => {
    setConfig({ currentSection: active })
  }, [active])

  return (
    <S.Wrapper ref={sectionRef}>
      <Block overflow="hidden">
        <SectionHero />
        <SectionAbout />
        <SectionPrice />
        <SectionSponsorCTA />
        <SectionSponsors />
        <SectionMadeBy />
        <SectionForm />
        <S.BtnSponsorWrapper>
          <S.ButtonSponsor
            tag="a"
            color="next100"
            size="sm"
            weight="semiBold"
            href="#patrocinar"
          >
            Quero Patrocinar
          </S.ButtonSponsor>
        </S.BtnSponsorWrapper>
      </Block>
    </S.Wrapper>
  )
}

export default View
