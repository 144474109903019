import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container } from 'react-awesome-styled-grid'
import { Block, Button, Title } from '@superlogica/design-ui'

import * as S from './styled'

const SectionSponsorCTA = () => (
  <S.SectionSponsorCTA>
    <S.BgElement>
      <StaticImage
        src="../../assets/images/generic/bg-sponsor.png"
        alt=""
        placeholder="blurred"
      />
    </S.BgElement>
    <Container>
      <S.Wrapper>
        <S.Content>
          <Title
            font="heading"
            size="display04"
            weight="medium"
            color="babar900"
          >
            Sua marca no Superlógica Next 2024
          </Title>
        </S.Content>
        <Block
          display="flex"
          alignItems="center"
          justifyContent={{ xxxs: 'start', sm: 'end' }}
          padding={{ xxxs: '60px 0', sm: '40px 0' }}
        >
          <Button
            tag="a"
            color="babar0"
            size="lg"
            weight="semiBold"
            href="#patrocinar"
          >
            Quero Patrocinar
          </Button>
        </Block>
      </S.Wrapper>
    </Container>
  </S.SectionSponsorCTA>
)

export default SectionSponsorCTA
