import React from 'react'
import { Row, Col } from 'react-awesome-styled-grid'
import {
  Button,
  dataLayerEvent,
  hubspotService,
  Input
} from '@superlogica/design-ui'
import { showToast } from '@superlogica/design-system'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { schema } from './Sponsor.util'

export type SponsorValues = {
  firstname: string
  email: string
  phone: string
  empresa: string
  website: string
  next__nicho_patrocinador: string
  sabendo_form_patrocinio: string
}

interface SponsorProps {
  hubspotId: string
  pageName: string
  googleEvent?: string
}

const Sponsor: React.FC<SponsorProps> = ({
  hubspotId,
  pageName,
  googleEvent
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<SponsorValues>({
    resolver: yupResolver(schema)
  })

  const onSubmitForm: SubmitHandler<SponsorValues> = async (data) => {
    const resp = await hubspotService.sendForm({
      formId: hubspotId,
      data,
      pageName
    })

    if (resp.status !== 200) {
      return
    }

    if (!!googleEvent) {
      dataLayerEvent(googleEvent)
    }

    reset()

    showToast({
      type: 'success',
      message: 'Mensagem enviada com sucesso!'
    })
  }

  return (
    <form id="form-sponsor" noValidate onSubmit={handleSubmit(onSubmitForm)}>
      <Row>
        <Col xs={12}>
          <Input
            className="mb-sm"
            {...register('firstname')}
            id="sponsor-firstname"
            label="Seu nome"
            errors={errors}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            className="mb-sm"
            {...register('email')}
            id="sponsor-email"
            type="email"
            label="E-mail"
            mask="lowercase"
            errors={errors}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            className="mb-sm"
            {...register('phone')}
            id="sponsor-phone"
            type="tel"
            label="Telefone"
            mask="phone"
            maxLength={15}
            errors={errors}
          />
        </Col>
        <Col xs={12}>
          <Input
            className="mb-sm"
            {...register('empresa')}
            id="sponsor-empresa"
            label="Empresa"
            errors={errors}
          />
        </Col>
        <Col xs={12}>
          <Input
            className="mb-sm"
            {...register('website')}
            id="sponsor-website"
            label="Qual o site da sua empresa?"
            errors={errors}
          />
        </Col>
        <Col xs={12}>
          <Input
            className="mb-sm"
            {...register('next__nicho_patrocinador')}
            id="sponsor-segmento"
            label="Qual o segmento da sua empresa?"
            errors={errors}
          />
        </Col>
        <Col xs={12}>
          <Input
            className="mb-sm"
            {...register('sabendo_form_patrocinio')}
            id="sponsor-sabendo_form_patrocinio"
            label="Como ficou sabendo do evento?"
            errors={errors}
          />
        </Col>
        <Col xs={12}>
          <Button
            color="sonic600"
            type="submit"
            weight="semiBold"
            isLoading={isSubmitting}
            fluid={true}
          >
            Solicitar contato
          </Button>
        </Col>
      </Row>
    </form>
  )
}

export default Sponsor
