import styled, { css } from 'styled-components'
import { parseThemeColor } from 'utils'
import { rgba } from 'polished'

export const Author = styled.cite`
  font-style: normal;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${({ theme }) => parseThemeColor('sonic500', theme)};
`

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: auto;
`

export const Wrapper = styled.div`
  padding: 8px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`
