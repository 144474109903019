import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title, Text, Button } from '@superlogica/design-ui'
import { isDesktop } from 'react-device-detect'
import { whatsapp } from 'config/app'

import * as S from './styled'

const SectionHero = () => {
  return (
    <S.SectionHero id="inicio">
      <S.Wrapper>
        <StaticImage
          src="../../assets/images/generic/bg-hero-element.png"
          alt="Palestrante"
          placeholder="blurred"
        />
      </S.Wrapper>
      <S.Content>
        <Container>
          <Block width="100%" display="grid" gap={60}>
            <Block maxWidth={860} paddingTop={{ xxxs: 60, sm: 0 }}>
              <Title
                font="heading"
                color="babar0"
                weight="bold"
                size="display01"
              >
                A maior arena de debates dos novos rumos dos mercados
                condominial e imobiliário
              </Title>
            </Block>
            <Block
              display="flex"
              justifyContent="space-between"
              alignItems={{ xxxs: 'start', xs: 'center' }}
              flexDirection={{ xxxs: 'column', xs: 'row' }}
              gap={16}
            >
              <Block>
                <Button
                  tag="a"
                  href="https://www.sympla.com.br/evento/superlogica-next-2024/2434611"
                  target="_blank"
                  size="lg"
                  color="next100"
                  weight="semiBold"
                  uppercase
                >
                  Compre seu Ingresso
                </Button>
              </Block>
              <Block
                display="flex"
                alignItems="center"
                marginTop={{ xxxs: 60, sm: 0 }}
              >
                <Block>
                  <Text
                    font="heading"
                    size="extraDisplay01"
                    color="next900"
                    weight="light"
                  >
                    19
                  </Text>
                </Block>
                <Block
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Text
                    font="heading"
                    size="display04"
                    weight="light"
                    lineHeight={0.8}
                  >
                    novembro
                  </Text>
                  <Text font="heading" size="display04" weight="bold">
                    Distrito Anhembi
                  </Text>
                  <Text
                    font="heading"
                    size="display06"
                    weight="semiBold"
                    lineHeight={0.5}
                  >
                    São Paulo
                  </Text>
                </Block>
              </Block>
            </Block>
          </Block>
        </Container>
      </S.Content>
    </S.SectionHero>
  )
}

export default SectionHero
