import styled from 'styled-components'

export const DateDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:not(:last-of-type) {
    position: relative;
    display: flex;
    align-items: center;
    font-variant-numeric: tabular-nums;
  }

  &:not(:first-child) {
    margin-left: 10px;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
