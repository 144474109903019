import React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title, Text, Button, Vector } from '@superlogica/design-ui'
import { isDesktop } from 'react-device-detect'
import { whatsapp } from 'config/app'

import * as S from './styled'

const SectionPrice = () => (
  <S.SectionPrice>
    <Container>
      <Block
        display="grid"
        gridTemplateColumns={{ xxxs: '1fr', sm: '1fr 2fr' }}
        gap={20}
        marginBottom={32}
      >
        <Block
          display="flex"
          alignItems="center"
          marginBottom={{ xxxs: 16, sm: 0 }}
        >
          <Title font="heading" color="babar0" weight="medium" size="display01">
            Quero ir no Next 2024
          </Title>
        </Block>
        <Block
          bgColor="next100"
          padding={{ xxxs: 32, sm: 60 }}
          borderRadius={24}
        >
          <Text
            className="badge badge__green"
            color="babar0"
            font="heading"
            size="display06"
            uppercase
            marginBottom={16}
          >
            1º Lote
          </Text>
          <Text
            font="heading"
            color="babar1000"
            weight="semiBold"
            size="heading"
          >
            R$ 399,00
          </Text>
          <Text
            font="heading"
            color="babar1000"
            size="display04"
            marginBottom={16}
          >
            Em até 12x de R$ 41,27
          </Text>
          <Button
            tag="a"
            href="https://www.sympla.com.br/evento/superlogica-next-2024/2434611"
            target="_blank"
            size="lg"
            color="next900"
            weight="semiBold"
            uppercase
          >
            Compre seu Ingresso
          </Button>
        </Block>
      </Block>
      <Block
        display="grid"
        gridTemplateColumns={{ xxxs: '1fr', sm: '1fr 1fr 1fr' }}
        gap={32}
      >
        <Block
          bgColor="babar0"
          borderRadius={24}
          padding={{ xxxs: 32, sm: 48 }}
          display="flex"
          flexDirection="column"
          gap={16}
        >
          <Vector name="exclamation" width={56} height={56} color="sonic500" />
          <Text
            font="heading"
            weight="light"
            size="display05"
            color="babar1000"
          >
            Tire suas dúvidas com nosso time de especialistas
          </Text>
          <Button
            tag="a"
            href={`${
              isDesktop
                ? `https://api.whatsapp.com/send?phone=${whatsapp.phone}&text=${whatsapp.msg}`
                : `whatsapp://send?phone=${whatsapp.phone}&text=${whatsapp.msg}`
            }`}
            color="success"
            target="_blank"
            size="sm"
            weight="semiBold"
          >
            Fale Conosco
          </Button>
        </Block>
        <Block
          display="flex"
          alignItems="start"
          justifyContent="center"
          flexDirection="column"
          gap={16}
          bgColor="next700"
          padding={{ xxxs: 32, sm: 60 }}
          borderRadius={24}
          className="opacity"
        >
          <Text
            className="badge badge__blue"
            color="babar0"
            font="heading"
            size="display06"
            uppercase
          >
            2º Lote
          </Text>
          <Text
            font="heading"
            color="babar0"
            size="display04"
            weight="semiBold"
          >
            12x de R$ 41,58
          </Text>
          <Text font="heading" color="babar0" weight="light" size="display05">
            R$ 499,00
          </Text>
        </Block>
        <Block
          display="flex"
          alignItems="start"
          justifyContent="center"
          flexDirection="column"
          gap={16}
          bgColor="next700"
          padding={{ xxxs: 32, sm: 48 }}
          borderRadius={24}
          className="opacity"
        >
          <Text
            className="badge badge__blue"
            color="babar0"
            font="heading"
            size="display06"
            uppercase
          >
            3º Lote
          </Text>
          <Text
            font="heading"
            color="babar0"
            size="display04"
            weight="semiBold"
          >
            12x de R$ 49,91
          </Text>
          <Text font="heading" color="babar0" weight="light" size="display05">
            R$ 599,00
          </Text>
        </Block>
      </Block>
    </Container>
  </S.SectionPrice>
)

export default SectionPrice
