import styled, { css } from 'styled-components'
import { parseColor, media } from '@superlogica/design-ui'

export const Wrapper = styled.ul``

export const Item = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
  padding: 1.5rem 0;

  span {
    margin-bottom: 0;
    &:first-of-type {
      flex: 0 0 180px;
    }
  }

  ${({ theme: { colors } }) => css`
    border-bottom: 1px solid ${parseColor(colors.babar100)};
  `}

  ${media.lessThan('md')} {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;

    span {
      margin-bottom: 0;
      &:first-of-type {
        flex: initial;
      }
    }
  }
`
