import styled, { css, keyframes } from 'styled-components'
import { parseColor, media } from '@superlogica/design-ui'
import { rem } from 'polished'
import { RibbonProps } from './Ribbon'

import { ribbon } from '../../../assets/svg/ribbon'

type WrapperRibbonProps = Required<Pick<RibbonProps, 'color'>> & {
  angle?: number
  top?: number
  bottom?: number
}

type HeadlineRibbonProps = Required<Pick<RibbonProps, 'slogan'>> &
  Pick<RibbonProps, 'duration' | 'delay'>

const ribbonAnimation = keyframes`
	from {
		background-position: 0 0;
	}
	to {
    background-position: 10000px 0;
	}
`

export const Headline = styled.span.withConfig({
  shouldForwardProp: (prop) => !['delay', 'duration'].includes(prop)
})<HeadlineRibbonProps>`
  display: block;
  width: 100%;
  height: 100%;
  animation: ${ribbonAnimation} 200s linear infinite;
  background-repeat: repeat-x;
  background-position: 0 0;
  background-size: auto 100%;

  background-image: url(${ribbon});

  ${({ delay }) =>
    delay &&
    css`
      animation-delay: ${delay};
    `}

  ${({ duration }) =>
    duration &&
    css`
      animation-duration: ${duration};
    `}
`

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['color', 'angle', 'top', 'bottom'].includes(prop)
})<WrapperRibbonProps>`
  width: 150vw;
  left: -25vw;
  position: absolute;
  z-index: 10;

  ${({ theme: { colors }, color, angle }) => css`
    background-color: ${parseColor(colors[color])};
    height: 100px;
    padding: ${rem(12)} 0;
    transform: rotate(${angle}deg);
  `}

  ${media.lessThan('md')} {
    height: 60px;
  }
`
