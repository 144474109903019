import * as React from 'react'
import { StatusItem } from 'components/app'
import { VectorProps } from '@superlogica/design-ui/dist/packages/ui/components/Vector'

import * as S from './CheckList.styled'

export interface ChecklistProps {
  items: string[]
  color?: Color
  fontSize?: FontSize
  vector?: VectorProps
  gridTemplateColumns?: number | string | Responsive<number | string>
}

const CheckList: React.FC<ChecklistProps> = ({
  items,
  vector,
  color = 'babar900',
  fontSize = 'body01',
  ...rest
}) => (
  <S.Wrapper {...rest}>
    {items.map((item, i) => (
      <S.Item key={`checklist-${i}`}>
        <StatusItem color={color} size={fontSize} vector={vector}>
          {item}
        </StatusItem>
      </S.Item>
    ))}
  </S.Wrapper>
)

export default CheckList
