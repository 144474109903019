import React from 'react'
import { Text, Vector, Block } from '@superlogica/design-ui'

import * as S from './Testimony.styled'

export interface TestimonyProps {
  body: string
  author: string
  color?: Color
}

const Testimony: React.FC<TestimonyProps> = ({
  body,
  author,
  color = 'sonic600'
}) => {
  return (
    <S.Wrapper color={color}>
      <Vector
        name="quote"
        width={42}
        height={42}
        color={color}
        marginBottom={16}
      />
      <Text marginBottom={32} color="babar900">
        {body}
      </Text>
      <S.Footer>
        <Block>{!!author && <S.Author>{author}</S.Author>}</Block>
      </S.Footer>
    </S.Wrapper>
  )
}

export default Testimony
