import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from 'react-awesome-styled-grid'
import { Block, Button, Text, Title, Carousel } from '@superlogica/design-ui'
import { StaticImage } from 'gatsby-plugin-image'
import { Video } from 'components/helpers'
import { Testimony } from 'components/app'
import { isDesktop } from 'react-device-detect'
import { whatsapp } from 'config/app'

// @ts-ignore
import conteudo from '../../assets/images/gifs/conteudo.gif'
// @ts-ignore
import mentoria from '../../assets/images/gifs/mentoria.gif'
// @ts-ignore
import negocios from '../../assets/images/gifs/negocios.gif'
// @ts-ignore
import palestras from '../../assets/images/gifs/palestras.gif'

import * as S from './styled'

interface TestimoniesDataProps {
  allTestimoniesJson: {
    nodes: {
      body: string
      author: string
    }[]
  }
}

const SectionAbout = () => {
  const {
    allTestimoniesJson: { nodes: testimonies }
  } = useStaticQuery<TestimoniesDataProps>(
    graphql`
      query {
        allTestimoniesJson {
          nodes {
            body
            author
          }
        }
      }
    `
  )

  return (
    <S.SectionAbout>
      <S.BgElement>
        <StaticImage
          src="../../assets/images/generic/bg-element-about.png"
          alt="Palestrante"
          placeholder="blurred"
        />
      </S.BgElement>
      <S.Content>
        <Container>
          <S.Card>
            <Block display="flex" flexDirection="column" alignItems="center">
              <S.CardHeading>
                <img src={conteudo} alt="" className="gif gif--purple" />
              </S.CardHeading>
              <Block display="flex" flexDirection="column" alignItems="center">
                <Text
                  size="heading"
                  font="heading"
                  weight="light"
                  className="number-purple"
                >
                  +40
                </Text>
                <Text
                  size="display05"
                  font="heading"
                  weight="medium"
                  textAlign="center"
                >
                  horas de
                  <br /> conteúdo
                </Text>
              </Block>
            </Block>
            <Block display="flex" flexDirection="column" alignItems="center">
              <S.CardHeading>
                <img src={mentoria} alt="" className="gif gif--blue" />
              </S.CardHeading>
              <Block display="flex" flexDirection="column" alignItems="center">
                <Text
                  size="heading"
                  font="heading"
                  weight="light"
                  className="number-blue"
                >
                  +100
                </Text>
                <Text
                  size="display05"
                  font="heading"
                  weight="medium"
                  textAlign="center"
                >
                  mentorias com
                  <br /> profissionais renomados
                </Text>
              </Block>
            </Block>
            <Block display="flex" flexDirection="column" alignItems="center">
              <S.CardHeading>
                <img src={negocios} alt="" className="gif gif--orange" />
              </S.CardHeading>
              <Block display="flex" flexDirection="column" alignItems="center">
                <Text
                  size="heading"
                  font="heading"
                  weight="light"
                  className="number-orange"
                >
                  +30
                </Text>
                <Text
                  size="display05"
                  font="heading"
                  weight="medium"
                  textAlign="center"
                >
                  marcas líderes na
                  <br /> Feira de Negócios
                </Text>
              </Block>
            </Block>
            <Block display="flex" flexDirection="column" alignItems="center">
              <S.CardHeading>
                <img src={palestras} alt="" className="gif gif--green" />
              </S.CardHeading>
              <Block display="flex" flexDirection="column" alignItems="center">
                <Text
                  size="heading"
                  font="heading"
                  weight="light"
                  className="number-green"
                >
                  +60
                </Text>
                <Text size="display05" font="heading" weight="medium">
                  palestrantes
                </Text>
              </Block>
            </Block>
          </S.Card>
          <Block padding={{ xxxs: '60px 0', sm: '100px 0' }} maxWidth={570}>
            <Title
              font="heading"
              color="babar0"
              size="display02"
              weight="medium"
              marginBottom={{ xxxs: 16, sm: 32 }}
            >
              Sobre o Next
            </Title>
            <Text color="babar0" size="body04" marginBottom={16}>
              Consolidado como o maior evento do mercado condominial, o
              Superlógica Next é um espaço de imersão em{' '}
              <strong>conhecimento</strong>, <strong>networking</strong>e
              <strong>oportunidades de negócio</strong>.
            </Text>
            <Text color="babar0" size="body04">
              Partindo de uma edição histórica no ano passado, o Next 2024
              amplia seus horizontes, trazendo uma programação especial para
              administradoras, síndicos e imobiliárias.
            </Text>
          </Block>
          <S.VideoWrapper>
            <Video
              video="997860464"
              title="O que te espera no Next 2024"
              announce="O que te espera no Next 2024"
            />
          </S.VideoWrapper>
          <Block
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding={{ xxxs: '40px 0', sm: '80px 0' }}
          >
            <Button
              tag="a"
              href="https://www.sympla.com.br/evento/superlogica-next-2024/2434611"
              target="_blank"
              size="lg"
              color="next100"
              weight="semiBold"
              uppercase
            >
              Compre seu ingresso
            </Button>
          </Block>
          <S.CardTestimonies>
            <Title
              font="heading"
              size="display02"
              color="babar800"
              textAlign="center"
              marginBottom={{ xxxs: 8, sm: 60 }}
            >
              Eu fui no Superlógica Next
            </Title>
            <Carousel
              fullMobile
              bulletColor="sonic600"
              activeBulletColor="sonic600"
              swiper={{
                loop: true,
                initialSlide: 0,
                autoplay: {
                  delay: 5000
                },
                observer: true,
                spaceBetween: 64,
                watchOverflow: true,
                simulateTouch: false,
                centeredSlides: false,
                centerInsufficientSlides: true,

                pagination: {
                  clickable: true
                },
                slidesPerView: 3,
                breakpoints: {
                  0: {
                    slidesPerView: 1
                  },
                  820: {
                    slidesPerView: 2
                  },
                  1024: {
                    slidesPerView: 3
                  }
                }
              }}
            >
              {testimonies.map(({ author, body }, i) => (
                <Testimony key={i} body={body} author={author} />
              ))}
            </Carousel>
          </S.CardTestimonies>
        </Container>
      </S.Content>
    </S.SectionAbout>
  )
}

export default SectionAbout
