import React from 'react'

import { appConfig } from 'config'
import { Config, Seo } from 'components/helpers'

import View from 'views/home'

const IndexPage: React.FC = () => (
  <>
    <Config data={appConfig} />
    <Seo description="O principal ponto de encontro das principais administradoras de condomínios do país será também o cenário do maior networking de síndicos profissionais do mercado condominial." />
    <View />
  </>
)

export default IndexPage
