import * as React from 'react'
import { Col, Container, Row } from 'react-awesome-styled-grid'
import { Block, Text, Title } from '@superlogica/design-ui'
import { SponsorForm } from 'components/forms'

import * as S from './styled'
import { StaticImage } from 'gatsby-plugin-image'

const SectionForm = () => (
  <S.SectionForm id="patrocinar">
    <S.BgElement>
      <StaticImage
        src="../../assets/images/generic/bg-sponsor.png"
        alt="Palestrante"
        placeholder="blurred"
      />
    </S.BgElement>
    <S.InnerSection>
      <Container>
        <Row align="center" justify="space-between">
          <Col xs={12} sm={5}>
            <Block
              tag="header"
              marginBottom={{ xxxs: 32, md: 0 }}
              className="content"
            >
              <Title
                font="heading"
                color="babar900"
                weight="medium"
                size="display02"
                marginBottom={16}
              >
                Quero patrocinar
              </Title>
              <Text color="babar900" size="body04">
                Para garantir a presença da sua marca no maior evento do mercado
                condominial e imobiliário do Brasil, preencha o formulário e
                entraremos em contato com você.
              </Text>
            </Block>
          </Col>
          <Col xs={12} sm={6}>
            <Block
              tag="article"
              bgColor="babar0"
              padding={{ xxxs: 32, md: 48 }}
              borderRadius={8}
              zIndex={4}
            >
              <SponsorForm
                hubspotId="9b43e5ad-3695-43db-938b-79b0b68805d0"
                pageName="Next 2022 - Home"
              />
            </Block>
          </Col>
        </Row>
      </Container>
    </S.InnerSection>
  </S.SectionForm>
)

export default SectionForm
