import styled, { css } from 'styled-components'

import { mapCSSPropsToStyled } from '@superlogica/design-ui'
import { ChecklistProps } from './CheckList'

type WrapperProps = Pick<ChecklistProps, 'gridTemplateColumns'>

export const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const Wrapper = styled.ul.withConfig({
  shouldForwardProp: (prop) =>
    !['columns', 'gridTemplateColumns'].includes(prop as string)
})<WrapperProps>`
  display: grid;
  column-gap: 16px;

  ${({ gridTemplateColumns }) => css`
    ${mapCSSPropsToStyled({ gridTemplateColumns })}
  `}
`
