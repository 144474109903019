import styled, { css } from 'styled-components'
import { parseColor } from '@superlogica/design-ui'
import { SpeechData } from 'fragments'

type SpeechAvatarProps = Required<Pick<SpeechData, 'color'>>

export const SpeechWrapper = styled.ul``

export const SpeechItem = styled.li.withConfig({
  shouldForwardProp: (prop) => !['color'].includes(prop)
})<SpeechAvatarProps>`
  padding: 1.5rem;

  ${({ theme: { colors }, color }) =>
    color &&
    css`
      background-color: ${parseColor(colors[color])};
    `}

  ${({ theme: { colors } }) => css`
    border-top: 1px solid ${parseColor(colors.babar100)};

    &:last-of-type {
      border-bottom: 1px solid ${parseColor(colors.babar100)};
    }
  `}
`

export const SpeechAvatar = styled.div.withConfig({
  shouldForwardProp: (prop) => !['color'].includes(prop)
})<SpeechAvatarProps>`
  width: 68px;
  height: 68px;
  max-width: 68px;
  border-radius: 100vh;
  border: 2px solid;
  flex: 2;
  overflow: hidden;

  .gatsby-image-wrapper {
    height: 100%;
  }

  img {
    object-fit: cover;
    object-position: top;
  }

  ${({ theme: { colors }, color }) =>
    color &&
    css`
      border-color: ${parseColor(colors[color])};
    `};
`

export const SpeechTalkerContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const SpeechTalker = styled.li`
  display: flex;
  align-items: center;
  gap: 0.875rem;
`

export const SpeechersWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  scroll-margin-top: 60px;
`
