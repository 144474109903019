import styled, { css } from 'styled-components'
import { parseColor, media } from '@superlogica/design-ui'
import { rgba, linearGradient } from 'polished'

import { CityProps } from './City'

type WrapperProps = Required<Pick<CityProps, 'apperance'>>

export const Figure = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

export const Inner = styled.div``

export const Body = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  span {
    align-self: flex-end;
  }
`

export const Header = styled.div`
  .label {
    text-transform: uppercase;
    span {
      font-size: 10px;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
`

export const NextArrow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  .vector-animation {
    width: 200%;
    height: 400%;
    left: -90%;
    top: -150%;
    z-index: 3;
  }

  .next-arrow {
    position: absolute;
    left: -90%;
    top: 50%;
    bottom: 0;
    margin: auto;
    z-index: 4;
  }

  ${media.lessThan('md')} {
    .vector-animation,
    .next-arrow {
      width: 170%;
    }
  }
  ${media.lessThan('sm')} {
    .vector-animation,
    .next-arrow {
      width: 140%;
    }
  }
  ${media.lessThan('xs')} {
    .vector-animation,
    .next-arrow {
      width: 185%;
    }
  }
`

export const Wrapper = styled.article.withConfig({
  shouldForwardProp: (prop) => !['apperance'].includes(prop)
})<WrapperProps>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease;

  .gatsby-image-wrapper {
    * {
      border-radius: 8px;
    }
  }

  .gatsby-image-wrapper img {
    transform: scale(1.01);
    transition: transform 0.5s ease;
  }

  &:hover {
    .gatsby-image-wrapper img {
      transform: scale(1.1);
    }
  }

  ${({ theme: { colors }, apperance }) => {
    if (apperance === 'default') {
      return css`
        border: 2px solid ${rgba(parseColor(colors.babar0), 0.1)};

        ${Body} {
          margin-top: auto;
        }

        &:hover {
          border: 2px solid ${parseColor(colors.warning)};

          ${Body} {
            span {
              color: ${parseColor(colors.babar1000)};
              border-color: ${parseColor(colors.warning)};
              background-color: ${parseColor(colors.warning)};
            }
          }
        }

        ${Figure} {
          ${({ theme: { colors } }) => css`
            &:after {
              ${linearGradient({
                colorStops: [
                  `${rgba(parseColor(colors.babar1000), 0.1)}`,
                  `${rgba(parseColor(colors.babar1000), 0.95)} 90%`
                ],
                toDirection: 'to bottom'
              })}
            }
          `};
        }
      `
    }
    if (apperance === 'full') {
      return css`
        border: 2px solid ${rgba(parseColor(colors.babar0), 0.1)};

        ${Inner} {
          max-width: 300px;
        }

        &:hover {
          border: 2px solid ${parseColor(colors.warning)};

          ${Body} {
            span {
              color: ${parseColor(colors.babar1000)};
              border-color: ${parseColor(colors.warning)};
              background-color: ${parseColor(colors.warning)};
            }
          }
        }

        ${Figure} {
          &:after {
            ${linearGradient({
              colorStops: [
                `${rgba(parseColor(colors.babar1000), 0.1)}`,
                `${rgba(parseColor(colors.babar1000), 0.8)} 90%`
              ],
              toDirection: 'to bottom'
            })}
          }
        }

        ${Content} {
          padding: 32px;

          ${media.lessThan('sm')} {
            padding: 24px;
          }
        }

        ${Body} {
          margin-top: auto;

          .date {
            margin-top: 16px;
          }

          ${media.lessThan('sm')} {
            flex-direction: column;

            span {
              align-self: flex-start;
              margin-top: 12px;
              color: ${parseColor(colors.neutral1000)};
              border-color: ${parseColor(colors.neutral1000)} !important;
            }
          }
        }
      `
    }
  }}
`
