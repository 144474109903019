import styled, { css } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'
import { rgba } from 'polished'

export const Caption = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
  height: 100%;

  ${media.lessThan('sm')} {
    padding: 24px 32px;
    height: auto;
  }
`

export const Figure = styled.div`
  width: 25%;
  min-width: 200px;
  height: 100%;
  mask-image: url(mask-desktop.svg);
  mask-repeat: none;
  mask-position: center;
  mask-size: cover;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    * {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  ${media.lessThan('sm')} {
    width: 100%;
    height: 240px;
    mask-image: url(mask-mobile.svg);
    mask-position: bottom center;

    .gatsby-image-wrapper {
      * {
        border-bottom-left-radius: 0;
        border-top-right-radius: 6px;
      }

      img {
        object-position: top center !important;
      }
    }
  }
`

export const Wrapper = styled.article`
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  height: 300px;

  ${media.lessThan('sm')} {
    flex-direction: column;
    height: auto;
  }

  ${({ theme: { colors } }) => css`
    background-color: ${rgba(parseColor(colors.sonic500), 0.08)};
    border: 1px solid ${parseColor(colors.sonic500)};
  `}
`
