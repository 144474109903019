import { object, string, boolean } from 'yup'

export const schema = object({
  firstname: string().required('campo obrigatório'),
  email: string().required('campo obrigatório').email('e-mail inválido'),
  phone: string()
    .required('campo obrigatório')
    .matches(/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/, 'telefone inválido'),
  empresa: string().required('campo obrigatório'),
  website: string().required('campo obrigatório'),
  next__nicho_patrocinador: string().required('campo obrigatório'),
  sabendo_form_patrocinio: string().required('campo obrigatório'),
  accept_terms: boolean().oneOf([true])
})
