import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Block, Title } from '@superlogica/design-ui'
import { Container } from 'react-awesome-styled-grid'
import { Link } from 'components/helpers'

import * as S from './styled'

const SectionSponsors = () => {
  return (
    <S.SectionSponsors id="patrocinadores">
      <Container>
        <Block display="flex" justifyContent="center" marginBottom={40}>
          <Title
            weight="medium"
            color="babar900"
            size="display02"
            textAlign="center"
            font="heading"
            maxWidth={660}
            tag="h2"
          >
            Conheça nossos patrocinadores e apoiadores
          </Title>
        </Block>
        <Block tag="header" marginBottom={24}>
          <Title
            color="babar900"
            size="display05"
            textAlign="center"
            font="heading"
            tag="h3"
          >
            Patrocinadores
          </Title>
        </Block>
        <Block
          display="grid"
          gridTemplateColumns="1fr"
          gap={80}
          marginTop={32}
          marginBottom={80}
        >
          {/* Patrocinador Gold */}
          <Block
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={24}
          >
            <Title
              weight="light"
              uppercase
              color="babar700"
              size="display04"
              textAlign="center"
              letterSpacing={1}
            >
              Gold
            </Title>
            <Block
              display="grid"
              gridTemplateColumns="1fr"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              gap={{ xxxs: 40, md: 60 }}
            >
              <Link
                to="https://srna.co/"
                target="_blank"
                title="Serena"
                rel="noopener noreferrer"
                className="brand brand--platinum"
              >
                <StaticImage
                  src="../../assets/images/logo/logo-serena.png"
                  alt="Logo Serena"
                  placeholder="blurred"
                  objectFit="contain"
                />
              </Link>
            </Block>
          </Block>
          {/* Patrocinador Bonze */}
          <Block
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={24}
          >
            <Title
              weight="light"
              uppercase
              color="babar700"
              size="display04"
              textAlign="center"
              letterSpacing={1}
            >
              Bronze
            </Title>
            <Block
              display="grid"
              gridTemplateColumns="1fr"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              gap={{ xxxs: 40, md: 60 }}
            >
              <Link
                to="https://www.desentupidoraimperio.com.br/"
                target="_blank"
                title="Imperio"
                rel="noopener noreferrer"
                className="brand brand--bronze"
              >
                <StaticImage
                  src="../../assets/images/logo/logo-imperio.png"
                  alt="Logo Imperio"
                  placeholder="blurred"
                  objectFit="contain"
                />
              </Link>
            </Block>
          </Block>
        </Block>
        <Block tag="header" marginBottom={24}>
          <Title
            color="babar900"
            size="display03"
            textAlign="center"
            font="heading"
            tag="h3"
          >
            Apoiadores
          </Title>
        </Block>
        {/* Apoiadores */}
        <Block
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={24}
        >
          <Block
            display="grid"
            gridTemplateColumns="1fr"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            gap={{ xxxs: 40, md: 60 }}
          >
            <Link
              to="https://gruvi.app/"
              target="_blank"
              title="Gruvi"
              rel="noopener noreferrer"
              className="brand brand--apoiador"
            >
              <StaticImage
                src="../../assets/images/logo/logo-gruvi.png"
                alt="Logo Gruvi"
                placeholder="blurred"
                objectFit="contain"
              />
            </Link>
          </Block>
        </Block>
      </Container>
    </S.SectionSponsors>
  )
}

export default SectionSponsors
