import React from 'react'

import * as S from './styled'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title, Vector } from '@superlogica/design-ui'

const SectionMadeBy = () => (
  <S.SectionMadeBy>
    <Container>
      <Block
        display="flex"
        justifyContent="center"
        padding={{ xxxs: '60px 0', sm: '80px 0' }}
        className="square"
      >
        <Block
          display="flex"
          alignItems="center"
          flexDirection={{ xxxs: 'column', sm: 'row' }}
          gap={{ xxxs: 24, sm: 120 }}
        >
          <Title font="heading" color="babar900" size="display03">
            Realização
          </Title>
          <Vector name="logo-superlogica-color" width={240} height={37} />
        </Block>
      </Block>
    </Container>
  </S.SectionMadeBy>
)

export default SectionMadeBy
