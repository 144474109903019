import styled, { css } from 'styled-components'
import { Button, media, parseColor } from '@superlogica/design-ui'

export const Wrapper = styled.div``
export const BgElement = styled.div``
export const Content = styled.div``
export const InnerSection = styled.div``
export const VideoWrapper = styled.div``
export const Card = styled.div``
export const CardHeading = styled.div``
export const CardTestimonies = styled.div``

export const BtnSponsorWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar25)};
  `}
`

export const ButtonSponsor = styled(Button)``

export const SectionHero = styled.section`
  position: relative;
  padding-top: 100px;
  padding-bottom: 200px;
  min-height: 120vh;
  z-index: 1;

  ${Wrapper} {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

    .gatsby-image-wrapper {
      min-height: calc(100vh + 200px);
      width: 100%;
      height: 100%;

      img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  ${Content} {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    align-items: center;
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar25)};
  `}

  ${media.lessThan('sm')} {
  }
`
export const SectionAbout = styled.section`
  position: relative;
  z-index: 2;

  .swiper {
    padding-bottom: 60px;
  }

  ${BgElement} {
    position: relative;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

    .gatsby-image-wrapper {
      width: 130%;
      height: 100%;
      position: absolute;
      top: 100px;
      left: -30%;
    }
  }

  ${Content} {
    position: relative;
    z-index: 4;
  }

  ${Card} {
    margin-top: -100px;
    padding: 100px 60px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    overflow: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom: 12px solid #f3aa3c;

    ${CardHeading} {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .gif {
      width: 180px;
      height: 180px;
      overflow: hidden;
      border-radius: 50%;
      border: 10px solid;

      &--purple {
        border-color: #ba61ff;
      }
      &--blue {
        border-color: #058af1;
      }
      &--orange {
        border-color: #ff730b;
      }
      &--green {
        border-color: #045937;
      }
    }

    .number-purple {
      color: #ba61ff;
    }
    .number-blue {
      color: #058af1;
    }
    .number-orange {
      color: #ff730b;
    }
    .number-green {
      color: #045937;
    }
  }

  ${CardTestimonies} {
    background-color: #ffffff;
    overflow: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom: 12px solid #f3aa3c;
    padding: 60px 48px 60px;
  }

  ${VideoWrapper} {
    overflow: hidden;
    border-radius: 32px;
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar25)};
  `}

  ${media.lessThan('md')} {
    ${Card} {
      margin-top: 0;
      padding: 40px 16px;
      grid-template-columns: 1fr 1fr;
      gap: 32px;
    }
    ${CardTestimonies} {
      padding: 60px 32px 16px;
    }
  }
  ${media.lessThan('sm')} {
    ${Card} {
      grid-template-columns: 1fr;
    }
  }
`
export const SectionPrice = styled.section`
  padding: 100px 0;
  background-color: #4f2de8;
  position: relative;
  z-index: 3;

  .opacity {
    opacity: 0.4;
  }

  .badge {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 50px;

    &__green {
      background-color: #045937;
    }

    &__blue {
      background-color: #1034f2;
    }
  }

  ${media.lessThan('md')} {
    padding: 60px 0;
  }
`
export const SectionSponsorCTA = styled.section`
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    width: 900px;
    height: 827px;
    border-radius: 0 60px 0 60px;
    position: absolute;
    top: -250%;
    right: 50%;
    transform: rotate(42deg);
    z-index: 2;
  }

  ${BgElement} {
    position: relative;
    width: 70%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: -20%;
    }
  }

  ${Wrapper} {
    position: relative;
    z-index: 3;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;

    ${Content} {
      padding: 40px 0;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 4;

      &:before {
        content: '';
        width: 100vw;
        height: 100%;
        position: absolute;
        left: -100vw;
        top: 0;
      }
    }
  }

  ${({ theme: { colors } }) => css`
    &:after {
      background-color: ${parseColor(colors.babar50)};
    }
    ${Content} {
      background-color: ${parseColor(colors.babar50)};
      &:before {
        background-color: ${parseColor(colors.babar50)};
      }
    }
  `}

  ${media.lessThan('xs')} {
    &:after {
      display: none;
    }
    ${BgElement} {
      width: 130%;

      .gatsby-image-wrapper {
        left: -20%;
      }
    }
    ${Wrapper} {
      grid-template-columns: 1fr;

      ${Content} {
        padding: 60px 0;

        &:before {
          width: 200vw;
          right: initial;
          left: -110%;
        }
      }
    }
  }
`
export const SectionSponsors = styled.section`
  position: relative;
  overflow: hidden;
  padding: 100px 0;

  .brand {
    border: 1px solid;
    border-radius: 8px;
    padding: 1rem 2rem;

    .gatsby-image-wrapper {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    &--platinum {
      width: 270px;
      height: 141px;
    }
    &--bronze {
      width: 152px;
      height: 80px;
    }
    &--apoiador {
      width: 118px;
      height: 61px;
    }
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar0)};

    .brand {
      color: ${parseColor(colors.babar200)};
      border-color: ${parseColor(colors.babar200)};
    }
  `}

  ${media.lessThan('sm')} {
    padding: 60px 0;
  }
`
export const SectionForm = styled.section`
  position: relative;
  overflow: hidden;
  z-index: 1;

  ${BgElement} {
    position: relative;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: -15%;
    }
  }

  ${InnerSection} {
    z-index: 2;
    padding: 60px 0;
    position: relative;
    z-index: 3;
    overflow: hidden;

    .content {
      position: relative;

      &::before {
        content: '';
        width: 900px;
        height: 827px;
        border-radius: 0 60px 0 60px;
        position: absolute;
        top: -190%;
        left: -110%;
        transform: rotate(42deg);
      }
    }
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar25)};

    .content {
      position: relative;

      &::before {
        background-color: ${parseColor(colors.babar25)};
      }
    }
  `}

  ${media.lessThan('sm')} {
    ${BgElement} {
      position: relative;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;

      .gatsby-image-wrapper {
        top: initial;
        right: 0;
        bottom: -30%;
      }
    }

    ${InnerSection} {
      .content {
        &::before {
          display: none;
        }
      }
    }
  }
`
export const SectionMadeBy = styled.section`
  position: relative;
  overflow: hidden;

  .square {
    position: relative;

    &::before {
      content: '';
      width: 800px;
      height: 727px;
      border-radius: 0 60px 0 60px;
      position: absolute;
      top: -120%;
      transform: rotate(42deg);
    }
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar100)};

    .square {
      position: relative;

      &::before {
        background-color: ${parseColor(colors.babar25)};
      }
    }
  `}

  ${media.lessThan('xs')} {
    .square {
      &::before {
        content: '';
        width: 350px;
        height: 350px;
        top: -30%;
      }
    }
  }
`
