import React from 'react'
import { Title, Text, Block, Button } from '@superlogica/design-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MentorProps } from 'fragments'

const Speech: React.FC<MentorProps> = ({
  name,
  role,
  bio,
  theme,
  link,
  avatar
}) => {
  return (
    <Block
      display="flex"
      flexDirection="column"
      alignItems="start"
      bgColor="babar0"
      borderRadius={4}
      padding={{ xxxs: 16, md: 24 }}
      height="100%"
      gap={16}
    >
      <Block
        display="grid"
        gridTemplateColumns="auto 1fr"
        justifyContent="start"
        alignItems="center"
        gap={16}
      >
        <Block width={64} height={64} borderRadius={32} overflow="hidden">
          {!!avatar?.childImageSharp && (
            <GatsbyImage
              image={avatar.childImageSharp.gatsbyImageData}
              objectFit="cover"
              alt={name}
            />
          )}
        </Block>
        <Block marginBottom={8}>
          <Title
            font="heading"
            color="next900"
            weight="bold"
            size="display06"
            lineHeight={1}
            tag="h3"
          >
            {name}
          </Title>
          <Text color="babar900" size="label02" weight="bold">
            {role}
          </Text>
        </Block>
      </Block>
      <Block
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Block marginBottom={16}>
          <Text color="babar700" size="label02">
            {bio}
          </Text>
          {!!theme && (
            <Text marginTop={16} size="label02">
              <strong>Tema mentoria:</strong> {theme}
            </Text>
          )}
        </Block>
        {!!link && (
          <Button
            color="babar100"
            size="sm"
            weight="semiBold"
            title="Esgotado"
            href={link}
            uppercase
          >
            Esgotado
          </Button>
        )}
      </Block>
    </Block>
  )
}

export default Speech
